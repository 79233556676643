import { nextTick } from "vue";

export default {
  props: {
    /**
     * Total items count
     */
    totalCount: {
      type: Number,
      default: 0,
    },
    /**
     * Current page
     */
    currentPage: {
      type: Number,
      default: 0,
    },
    /**
     * Size of each page
     */
    pageSize: {
      type: [Number, String],
      default: 0,
    },
    forceShow: {
      type: Boolean,
      default: false,
    },
    handlesPageSize: {
      type: Boolean,
      default: false,
    },
    pageSizes: {
      type: Array,
      default: [1, 10, 25, 50, 100, 250, 500],
    },
  },
  data: function () {
    return {
      test: 0,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalCount / this.pageSizeSafe);
    },
    pageSizeSafe() {
      let result = Number(this.pageSize) != 0 ? Number(this.pageSize) : 1;
      this.test = result;
      return result;
    },
    computedPaginationSizeSelectList() {
      let result = [];
      this.pageSizes.forEach((size) => {
        result.push({
          value: size,
          label: this.translate("core.pagination.selectSizeGenericLabel", {
            size,
          }),
        });
      });
      return result;
    },
    nextPageLinks() {
      let result = [];
      // compute total next pages
      let nextPageCount = this.totalPages - (this.currentPage + 1);

      // link for next page
      if (nextPageCount > 0) {
        result.push({
          label: this.currentPage + 2,
          page: this.currentPage + 1,
          disabled: false,
        });
      }

      // link for uber-next page
      if (nextPageCount > 1) {
        result.push({
          label: this.currentPage + 3,
          page: this.currentPage + 2,
          disabled: false,
        });
      }

      // link for ... to indicate many pages
      if (nextPageCount > 2) {
        result.push({
          label: "...",
          page: false,
          disabled: true,
        });
      }

      // link for last page
      if (nextPageCount > 2) {
        result.push({
          label: this.totalPages,
          page: this.totalPages,
          disabled: false,
        });
      }

      return result;
    },
    prevPageLinks() {
      let result = [];

      // compute total next pages
      let prevPageCount = this.currentPage;

      // link for first page
      if (prevPageCount > 2) {
        result.push({
          label: 1,
          page: 0,
          disabled: false,
        });
      }

      // link for ... to indicate many pages
      if (prevPageCount > 3) {
        result.push({
          label: "...",
          page: false,
          disabled: true,
        });
      }

      // link for uber-prev page
      if (prevPageCount > 1) {
        result.push({
          label: this.currentPage - 1,
          page: this.currentPage - 2,
          disabled: false,
        });
      }

      // link for prev page
      if (prevPageCount > 0) {
        result.push({
          label: this.currentPage,
          page: this.currentPage - 1,
          disabled: false,
        });
      }

      return result;
    },
    computedPageSize: {
      get() {
        return this.pageSize;
      },
      set(val) {
        this.setPageSize(val);
      },
    },
  },
  methods: {
    goToFirst() {
      return this.goToPage(0);
    },
    goToPrev() {
      return this.goToPage(this.currentPage - 1);
    },
    goToNext() {
      return this.goToPage(this.currentPage + 1);
    },
    goToLast() {
      return this.goToPage(this.totalPages - 1);
    },
    goToPage(page) {
      if (page < 0) {
        page = 0;
      }

      if (page > this.totalPages - 1) {
        page = this.totalPages - 1;
      }

      this.$emit("update:currentPage", Number(page));
    },
    setPageSize(size) {
      this.$emit("update:pageSize", Number(size));
    },
  },
  watch: {
    currentPage: {
      handler: function (newVal) {
        // we need to use next tick, because we need to wait for current pagination calculations to end
        nextTick(() => {
          if (newVal > this.totalPages) {
            this.$emit("update:currentPage", this.totalPages - 1);
          }

          if (newVal < 0) {
            this.$emit("update:currentPage", 0);
          }
        });
      },
      // this is required for some reason
      immediate: true,
    },
  },
};
